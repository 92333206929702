import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - अध्याय १० वा" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='अध्याय १० वा' num='10'/>
        <div id="main">
          <section id="content" className="main">
            <p>
            श्री गणेशाय नम: । हे ईशा परमेश्वरा । मनीच्या व्यथा ऐक जरा । तूच सहाय्यभूत होतोस खरा ॥ १ ॥
तू ऐशा भक्ताचे ऐकतो । जो तुझाच होऊन रहातो । तूच प्रसंगी तारतो । त्याला आनंदी ठेवतो ॥ २ ॥
हे गणामहाराजा कळले । जेणे तुझ्यात चित्त गुंतले । सरस्वतीने मज बसविले । सुविचार सुमने धुंद केले ॥ ३ ॥
गणामहाराज गुरफटलेला व्यवहारी । प्रपंचात जीव भारी । तैसाच मूळ व्यापारी । बेजारे करतो नोकरी ॥ ४ ॥
गुरूमंत्राच्या श्रद्धेवरी । गुरूस्मरण अष्टौप्रहरी । मनी नाना विचार लहरी । चित्ता अस्वस्थ करी ॥ ५ ॥
कैसे होईल ग्रंथ लेखन । दशावतारा तुझे स्मरण । कर तू माझे रक्षण । वर्षाव कर कृपा पर्जन्य ॥ ६ ॥
जेणे मन प्रफुल्लित होईल । गुंतेल ते ग्रंथ लेखनात । नको सभोवतालात । तेणे अपयश पदरात ॥ ७ ॥
अध्याय लिहितो दहावा । पूजने गुरू प्रसन्न व्हावा । ऐसा वर्ताव करावा । सदैव आशिर्वाद मिळावा ॥ ८ ॥
ज्याला आपण गुरू मानतो । तो पूजता ईश्वर संतोषतो । मनोकामना पूर्ण करतो । भक्ता सदा तोषतो ॥ ९ ॥
ऐसाच अमरावतीचा भक्त । आत्माराम भिकाजी म्हणतात । सदा गुरू सेवा भावात । दिसत होता कृतीत ॥ १० ॥
महाराज आले त्याच्या घरी । गुरू पूजा झाली घरी । पूजा ज्या घरची स्विकारी । भाग्योदय सत्वरी ॥ ११ ॥
बरेच भक्त पूजेच्या वेळी । आनंद लुटू लागली । बघू लागली गुरू माऊली । जी नामात तल्लीन झालेली ॥ १२ ॥ 
बाळाभाऊ भाचा । होता आत्मारामाचा । पहाता झाला पूजेचा । थाट गुरूपूजनाचा ॥ १३ ॥
कधीचे त्याच्या मनात असलेले । आज उफाळून आले । गुरूचरण त्याने धरले । कृपाशिष मागितले ॥ १४ ॥
ऐसा आशिर्वाद दिला । शिष्य होऊन राहिला । नोकरी पार विसरला । नोकरीस रामराम ठोकला ॥ १५ ॥
नाही पर्वा नोकरीची । संसार बायको मुलांची । ईच्छा कधीची मनाची । आज पूर्ण झाली साची ॥ १६ ॥
गणेश श्रीकृष्ण खापर्डे । वकीलीत नामांकित बडे । अमरावतीत प्रस्थ बडे । वकीलीत रूपयांचे ढीग पडे ॥ १७ ॥
होते धार्मिक स्वभावाचे । पूजा अर्चा करायचे । सज्जन सद्गृहस्थाचे । आचार होते त्यांचे ॥ १८ ॥
ईच्छा त्यांच्या मनाला । गुरू माऊली यावी घराला । समर्थांनी जाणले त्याला । गेले त्यांच्या घराला ॥ १९ ॥
दादासाहेब खापर्ड्यांनी । पूजन केले अत्यादरानी । संतोषावे समर्थांनी । हीच ईच्छा मनोमनी ॥ २० ॥
गणेश अप्पा लिंगायत वाणी । होता पूजेच्या स्थानी । चंद्रभागा अर्धांगिनी । होती समवेत त्या स्थानी ॥ २१ ॥
पती पत्नींनी नमस्कार । केला समर्थांस साचार । पूजेचा मनातला विचार । समर्थे जाणला साचार ॥ २२ ॥
चंद्रभागा बोलली पतीला । समर्था न्यावे सदनाला । करावा पूजेचा सोहळा । करावे विनंतीला ॥ २३ ॥
पती म्हणे पत्नीला । वेड लागले तुजला । खापर्डे गृहस्थ बडा भला । तेणे पूजा सोहळा । झाला त्याचे घराला ॥ २४ ॥
साधू न्यावया घरास । वशीला लागतो त्यास । जर असता नशीबास । गुरू येतो घरास ॥ २५ ॥
चंद्रभागा म्हणे पतीस । ऐकावे मनोदेवतेस । साधू येईल घरास । वाटते माझ्या मनास ॥ २६ ॥
गणेश अप्पा बोलेना । त्याची हिंमत होईना । नाना विचार मना । कैसे सांगावे जना ? ॥ २७ ॥
समर्थे ओळखला विचार । धरला त्याचा कर । म्हणे दाखव तुझे घर । करीन पूजेचा स्विकार ॥ २८ ॥
गणेश अप्पांची पूजा स्विकारली । पती पत्नी धन्य झाली । मनोवांच्छा फळास आली । जी मनी होती वसली ॥ २९ ॥
प्रत्येक पूजेच्या वेळेस । बाळाभाऊ हजर त्यास । ओळखले शाश्वतास । बसला त्याचा विश्वास ॥ ३० ॥
बाळाभाऊ भारावून गेला । समर्थांस पाहू लागला । भक्तीचा मनोनिर्धार केला । नोकरीतून राजीनामा दिला ॥ ३१ ॥
गणेश अप्पा नि चंद्रभागेस । काही न सुचे मनास । गुरू सेवेचा सदा ध्यास । हाच विचार मनास ॥ ३२ ॥
दोघे झाली समर्थ भक्त । संसारातून विरक्त । ओळखले शाश्वत । रमले शाश्वतात ॥ ३३ ॥
पुढे महाराज निघाले । शेगावास प्रयाण केले । कुणाच्या घरी न गेले । मोट्यांच्या मंदिरी बसले ॥ ३४ ॥
मंदीराच्या पूर्वेस । जागा पडली ओस । तीच समर्थांस उचित । बसले त्या जागेस ॥ ३५ ॥
कृष्णाजीच्या घरास । न भुलले वैभवास । जायचे कुणाच्याही घरास । येईल त्यांच्या मनास ॥ ३६ ॥
कळले वर्तमान कृष्णाजीला । मनोमनी दुःखी झाला । महाराजांकडे येता झाला । महाराजांस विनवू लागला ॥ ३७ ॥
श्री गुरू ! काय केले उणे ? । ऐसे झाले वागणे । माझा मळा सोडणे । हे उचित नसे ॥ ३८ ॥
चुकीची माफी असावी । कृपादृष्टी असावी । ही जागा न पसंत करावी । विनंती माझी ऐकावी ॥ ३९ ॥
देशमुखाजवळची जागा । वैरभावाची जागा । दुसरी मागावी जागा । म्हणाल ती देऊ जागा ॥ ४० ॥
नाही तोटा जागेस । रहावे माझ्या घरास । समर्थ आठवती बंकटास । म्हणती निरोप धाडा त्यास ॥ ४१ ॥
आश्चर्य ऐसे झाले । बंकटाचे येणे झाले । कृष्णाजीस म्हणाले । काढ विचार मनातले ॥ ४२ ॥
पहाते झाले बंकटाकडे । हाच सोडवेल कोडे । जे झाले बंकटाकडे । तेच आज येथे घडे ॥ ४३ ॥
बंकट मूळचा हुशार भारी । एक चतुर व्यवहारी । तसाच तो व्यापारी । काय बोलणार त्यावरी ? ॥ ४४ ॥
बंकट बोलता झाला । सोडले माझ्या घराला । तोच प्रसंग येथे आला । न स्थिर कुणाच्या घराला ॥ ४५ ॥
समर्थां न दुखवावे । त्यातच हित मानावे । जे जे त्यांच्या मनी यावे । तसे त्यांना वागू द्यावे ॥ ४६ ॥
गुरू कधी न दुखवावा । गुरू सदा तोषवावा । बोल प्रमाण मानावा । आपला हट्ट सोडावा ॥ ४७ ॥
बोलले जाते अंतरंगातले । न दुसरे भलते बोले । कृष्णाजीसही पटले । समर्थ त्यावर हसले ॥ ४८ ॥
सखाराम आसोलकरांनी । दिली जागा मनापासुनी । परिश्रम केले सावजींनी । मठाच्या बांधकामा झणी ॥ ४९ ॥
महाराज एकदा भक्तांसमवेत । बैसले होते मठात । स्वारी होती रंगात । चिलीमीच्या मस्तीत ॥ ५० ॥
एका वेगळ्याच तंद्रीत । एका वेगळ्याच आनंदात । चिलिम होती मुखात । गण गण मंत्र मनात ॥ ५१ ॥
काळजी घेत होता भास्कर । चिलिम देत होता सत्वर । काळजी घेण्यात तत्पर । न भास्करासारखा होणार ॥ ५२ ॥
छंद होता चिलिमीचा । व्यसन न म्हणण्याचा । अहर्निष जप चाले ज्याचा । दुसरे काय बोलणार वाचा ? ॥ ५३ ॥
काया वाचा मनाने रंगला । सदा भक्तांसाठी खपला । ईश्वरेच्छा पूर्ण करायला । जणू तो अवतरला ॥ ५४ ॥
भास्कर बाळाभाऊ पितांबर । रामचंद्र गुरव परिवार । गणेश अप्पा नि इतर । सेवेत होते तत्पर ॥ ५५ ॥
भक्तीत जेव्हा स्पर्धा होते । तीच बाधक ठरते । ऐसे जेथे दिसते । चढाओढ तेथे होते ॥ ५६ ॥
तैसेच येथे झाले । भास्करास ऐसे वाटले । बाळाभाऊने दंभ आचरले । पेढे बर्फीस जवळ केले ॥ ५७ ॥
सेवेचे ढोंग आचरले । प्रपंचास लाथाडले । काही तरी आडवे आले । तेणे नोकरीस सोडले ॥ ५८ ॥
हा न येथे टिकावा । गुरूने हेतू ओळखावा । बाळाभाऊस घालवावा । स्पर्धेचा खेळ संपवावा ॥ ५९ ॥
गुरूने जाणले सकळ । भास्कराची तळमळ । आज्ञा झाली तत्काळ । बोलावले बाळाभाऊस जवळ ॥ ६० ॥
फाडफाड मारतात । बाळाभाऊच्या थोबाडीत । राहू नको तू मठात । सवकला पेढे बर्फीत ॥ ६१ ॥
बाळाभाऊस कळेना । काय केला आपण गुन्हा ? । केल्या विनवण्या नाना । ऐसे का हो आज मना ? ॥ ६२ ॥
समर्थ खूप संतापले । छत्रीने झोडपू लागले । मोडण्या इतुके झोडपले । काही कुणा न कळले ॥ ६३ ॥
काठीनेही मारल्यावर । लाथा बुक्क्यांचा मार । झाला त्याच्या पाठीवर । संतोषला भास्कर ॥ ६४ ॥
वाटले आता भास्करास । बाळाभाऊ न येईल मठास । जैसा प्रसंग विठोबास । तैसा बाळाभाऊस ॥ ६५ ॥
बाळाभाऊ जाईल कायमचा । मोकळा मार्ग सेवेचा । नष्ट भाव स्पर्धेचा । काटा निघेल कायमचा ॥ ६६ ॥
स्पर्धा सतावे भारी । नाना कळा अंतरी । त्या कृतीत उतरी । भक्तीला ती अंतरी ॥ ६७ ॥
भक्तीत जाते तड । स्पर्धा मुळातच जड । घेता तिचा कड । भक्तीला न मिळे सवड ॥ ६८ ॥
रहाट गाडगे ऐसेच चाले । भास्करास न सोडले । परी गुरूकृपे कोडे उलगडले । दुकर सुकर झाले ॥ ६९ ॥
भास्करास पहावेना । विनवण्या महाराजांना । झाला व्यथीत मना । म्हणे मोठा केला गुन्हा ॥ ७० ॥
बाळाभाऊस सोडवले । महाराज थांबले । बाळाभाऊस पाहिले । वळाचे नावनिशाण न दिसले ॥ ७१ ॥
कळला बाळाभाऊचा अधिकार । गुरूनिष्ठेचा भक्तीचा अधिकार । समजला भास्कर । म्हणे चूक न ऐशी घडणार ॥ ७२ ॥
तैसाच वागला भविष्यात । न आड कुणाच्या भक्तीत । राहू दिले गुरूसेवेत । जो जैशा सेवाभावात ॥ ७३ ॥
आप्तेष्ट पीडा तापदायक । गुरूचा मार न बाधक । जो खरा असतो सेवक । अन्यथा विपरीतच ॥ ७४ ॥
हवे हवेसे पावेतो । वस्तुंवर प्रेम जडते । मन वीटता नकोशी होते । महत्व कमी होते ॥ ७५ ॥
नाही वस्तुंचे । केवळ निर्जीवाचे । परी होते सजीवांचे । पशू पक्षी प्राण्यांचे ॥ ७६ ॥
जनावरांस मन असते । प्रेम मालकावर असते । ईमानीपणे जगणे होते । पूर्वसंचितानुसार होते ॥ ७७ ॥
जीवनात महत्व पूर्वसंचिताला । जेणे प्रारब्ध वाट्याला । सुकलालच्या गायीला । प्रारब्ध होते नशिबाला ॥ ७८ ॥
सुकलाल आगरवाला । रहाणार बाळापुरला । शेतीवाडीने संपन्न भला । गायी गुरांचा गोतावळा ॥ ७९ ॥
धन धान्य होते वाट्याला । वैभव होते नशिबाला । परी एक गाय नशिबाला । आडदांडपणा देहाला ॥ ८० ॥
गायीच्या देहरूपात । तेणे गाय म्हणण्यात । हिंस्त्रपणा आचरण्यात । सैरावैरा पळण्यात ॥ ८१ ॥
चुकून गाय सुट्टी रहाता । घेई शिंगावर पहाता पहाता । वाटेल त्या दुकानात शिरता । तेथले भयभीत चित्ता ॥ ८२ ॥
वाटेल तेथे तोंड खुपसावे । धनधान्य नासाडावे । आखल्यास्तव वागावे । जे गायीस न शोभावे ॥ ८३ ॥
गुंडास्तव वागावे । लोकांना धाकात ठेवावे । जिकडे तिकडे तीने जावे । थैमान घालावे ॥ ८४ ॥
लोक त्रासास कंटाळले । सुकलालचे मित्र शत्रू झाले । वैमनस्य वाढले । ताप असह्य झाले ॥ ८५ ॥
तो झाला चिंताक्रांत । घरचे झाले सर्व त्रस्त । घर विपत्तीग्रस्त । आनंदी वातावरण लुप्त ॥ ८६ ॥
ईश्वर स्वतःचे महत्व टिकवतो । प्रसंग निमित्त ठरतो । तेणे सत्संग करवतो । जनास मार्ग दावतो ॥ ८७ ॥
कर्ता करविता विधाता । तोच बनेल त्राता । प्रारब्ध भोग वाट्यास येता । सुसह्य करे जगत त्राता ॥ ८८ ॥
गावकर्‍यांनी सुचवावे । ते ते त्याने करावे । निराशेस पत्करावे । काही न चालावे ॥ ८९ ॥
गावकरी झाले वदता । गायीस विकावे आता । क्लेश टाळावे आता । जगू द्यावे स्वस्थ चित्ता ॥ ९० ॥
गायीस नेले विकण्या । कुणी विकत घेईना । क्लेश काही टळेना । प्रश्र्न पडे सर्वांना ॥ ९१ ॥
कवडी मोल वस्तू होता । वस्तू निकामी होता । मोबदल्याचे न महत्व देता । मोफत दिली जाते तत्वता ॥ ९२ ॥
कुणी न घेई गायीस । घाबरे तिच्या आडदांडपणास । मित्र परिवार वदे सुकलालास । विकावी खाटकास ॥ ९३ ॥
सुकलालास पटेना । गायीस खाटका देण्या । जरी होता आडदांडपणा । परी हिम्मत होईना ॥ ९४ ॥
पूर्व संस्कार व्हायचे जागृत । नाना विचार येई मनात । नको पापाच्या जाळ्यात । कधी न सुटका त्यात ॥ ९५ ॥
लोकांवर प्रारब्ध सोपवले । गायीस मारणे नक्की केले । पठाणास सर्व सोपवले । सुटकेचे निश्वास टाकले ॥ ९६ ॥
पठाणाने काय केले । बंदुकीचे नेम धरले । नेम त्याचे चुकले । काळाने न साथ दिले ॥ ९७ ॥
गायीस जणू हे कळले । पठाणास भोसकले । पठाणाचे प्राण तडफडले । पठाणास पंचत्व आले ॥ ९८ ॥
भलताच प्रसंग ओढवला । पठाण फुकटचा मेला । सर्वांस प्रश्र्न पडला । काय करावे गायीला ? ॥ ९९ ॥
गावकरी न बसती स्वस्थ । एकच विचार करी त्रस्त । जो तो गायीच्या सुटकेत । नाना तर्‍हे प्रयत्नात ॥ १०० ॥
एकच विचार सतावे सर्वांना । प्रवृत्ती करवे नाना । प्रारब्धापुढे चालेना । कुणास काही सुचेना ॥ १०१ ॥
काही काळ लोटता । गावकरी झाले ठरवता । गायीस शेगावी न्यावी आता । अर्पण करावी गुरूनाथा ॥ १०२ ॥
अर्पण करावी गजाननास । पुण्य येईल उदयास । टाळू गायीच्या पीडेस । योग्य विचार वाटे मनास ॥ १०३ ॥
पुण्याई नव्हती सुकलालाची । नव्हती गावकर्‍यांची । पुण्याई होती गायीची । एका मुक्या जनावराची ॥ १०४ ॥
गायीला बांधण्यात आले । बांधुन गाड्यात ठेवले । गायीस ऐसे बांधले । एकही अवयव न शिल्लक ठेवले ॥ १०५ ॥
कष्टांची परिसीमा झाली । सत्वपरिक्षेस उतरली । पराधीन असलेली । पराधीनतेस कंटाळलेली ॥ १०६ ॥
जाताना मृत्यूलोकातून । जावे लागते यातना सोसून । सारे प्रारब्धावर अवलंबून । सुटका न होणे त्यातून ॥ १०७ ॥
एकातून दुसर्‍यात जाण्यास । नाना त्रास होती जीवास । योनीतून सुटका होण्यास । सोसावे लागते जीवास ॥ १०८ ॥
गायीची पुण्याई उदयास । संपवता पापकर्मास । माहित नव्हते गावकर्‍यांस । जेणे आले शेगावास ॥ १०९ ॥
मंडळी शेगावी आली । गायीस मठात आणली । गुरूमाऊलीस हाक मारली । गुरू माऊली उभी राहिली ॥ ११० ॥
समर्थांनी पाहिले गायीला । बंदिस्त मुक्या जनावराला । समर्थे प्रश्र्न केला । का हो बांधले गोमातेला ? ॥ १११ ॥
गावकरी समर्थांस वदले । उगाच न हीस बांधले । गायीने थैमान घातले । सारे गाव डोक्यावर घेतले ॥ ११२ ॥
समर्थ हसले अज्ञानाला । वदते झाले तत्क्षणाला । “अहो सोडा आता गायीला । ती न मारणार कुणाला” ॥ ११३ ॥
गायीस सोडण्यात आले । तत्क्षणी तिने चरण धरले । समर्थे स्पर्श केले । स्पर्शातून तिला समजले ॥ ११४ ॥
कुणास त्रास देऊ नये । आडदांडपणा करू नये । सारे आपलेच समजावे । जीवन उपयुक्त ठरावे ॥ ११५ ॥
गायीस सारे समजले । तिने मानेने होकार दिले । तिला पश्चाताप झाले । तिच्या कृतीत दिसले ॥ ११६ ॥
पूर्वजन्माचे संबंध । होते त्यांचे ऋणानुबंध । हाच होत होता बोध । समजला गावकरी वृंद ॥ ११७ ॥
गावकर्‍यांस आश्चर्य वाटले । त्यांना अज्ञान कळले । समर्थांचे सामर्थ्य कळले । महाराजांचे चरण धरले ॥ ११८ ॥
गाय तेथेच राहिली । वृत्ती तिची बदलली । सर्वांकडे पाहू लागली । आसवे नयनातून ओघळली ॥ ११९ ॥
सारी मंडळी व्यथित झाली । पश्चातापे बोलू कागली । उगाच हिची दैना केली । गोमातेस त्रस्त केली ॥ १२० ॥
समर्थांची माफी मागितली । गावकर्‍यांनी वाट धरली । तिचीच संतती नांदू लागली । धन्य ती जीवनी ठरली ॥ १२१ ॥
सुकलालच्या नव्हते नशीबात । ते होते गायीच्या नशीबात । सुकलाल परतला गावात । गाय राहिली शेगावात ॥ १२२ ॥
अन्न उदक असतो जेथे । तो पावेतोच तेथे । ते संपता जाणे होते । दुसरीकडे जाणे होते ॥ १२३ ॥
पूर्वसंचिताच्या खेळाला । महत्व पूर्वसंचिताला । पूर्वसंचिते भाग्य उदयाला । अनुभव आला लक्ष्मणाला ॥ १२४ ॥
कारंज्याच्या लक्ष्मणास । पोटदुखीने घेरले त्यास । नाना औषधे उपचारास । परी न आराम पडे त्यास ॥ १२५ ॥
उपाय निरुपाय ठरले । शेगावी जाणे नक्की केले । पती पत्नी शेगावी आले । समर्थांचे चरण धरले ॥ १२६ ॥
तिने पदर पसरला । व्यथा सर्व सांगितल्या । औषधोपचार निकामा ठरला । आलो तीर्थ मागायला ॥ १२७ ॥
समर्थ आंबा खात होते । परी लक्ष व्यथेकडे होते । दुःख सारे जाणत होते । बोलणे तिचे ऐकत होते ॥ १२८ ॥
आळवल्याने समर्थ प्रसन्न झाले । उष्ट्या आंब्यास फेकले । लक्ष्मणाकडे टक लाऊन बघितले । गुरूकृतीचे गूढ न कळले ॥ १२९ ॥
पती पत्नीस अचंबा वाटला । भक्त वदले तिला । जावे आता कारंज्याला । आंबा खाऊ घाल पतीला ॥ १३० ॥
दोघे गेले कारंज्याला । वृत्तांत सारा सांगितला । मुर्खात काढले तिला । म्हणे रोग बळावणार त्याला ॥ १३१ ॥
पडू नको भानगडीत । न होणार तो व्याधीमुक्त । आंबा करेल व्याधीग्रस्त । भर पडेल दुखण्यात ॥ १३२ ॥
श्रद्धेचे महत्व दोघांना । स्मरण केले समर्थांना । उष्टा आंबा खाताना । कैसे पटणार गावकर्‍यांना ? ॥ १३३ ॥
लक्ष्मणाने आंबा खाल्ला । प्रकृतीत आराम पडला । समर्थांचा अधिकार कळला । जेणे रोग निवारला ॥ १३४ ॥
काहि काळ लोटला । महाराज गेले कारंज्याला । भास्कर बाळाभाऊ सहाय्याला । होते तत्पर गुरूसेवेला ॥ १३५ ॥
लक्ष्मणाने आमंत्रण केले । समर्थे ते स्विकारले । पूजेचे प्रयोजन झाले । आदरातिथ्य झाले ॥ १३६ ॥
लक्ष्मणाने सोहळा केला । परी मनातला भाव वेगळा । दांभिकपणा ओळखला । समर्थांस न तो खपला ॥ १३७ ॥
लक्ष्मण वदे महाराजांस । गुरूचरण लाभले घरास । तेणे भाग्य उदयास । आशिर्वाद असावे लेकरांस ॥ १३८ ॥
जे जे ह्या घरचे । सर्वच आहे तुमचे । सर्वच तुमच्या कृपेचे । माझे काय ह्या घरचे ? ॥ १३९ ॥
समर्थांस फिरवले घरभर । संतोषला तो क्षणभर । धन धान्य कोठार । आता नक्की भरणार ॥ १४० ॥
सर्व दाखवले गजाननाला । परी न कोठाराला । काय कळणार गजाननाला । ऐसे वाटले मनाला ॥ १४१ ॥
समर्थे पहाता कोठाराला । रिकामे करावे लागेल त्याला । लपवू लागला वैभवाला । राग आला गजाननाला ॥ १४२ ॥
समर्थ वदले त्याला । सांभाळावे कोठाराला । नको कोठार आम्हाला । हे न येणार कामाला ॥ १४३ ॥
लक्ष्मणाचे नशीब आड आले । गजानन तेथून निघाले । सहा महिने लोटते झाले । कोठार सर्व रिकामे झाले ॥ १४४ ॥
दांभिकपणाचा परिणाम । वैभवास ठोकला रामराम । हाती आलेले वैकुंठधाम । झाले स्मशानधाम ॥ १४५ ॥
गुरू नाही करणार । कधी रिकामे कोठार । नाही त्याचेवर जगणार । नको भलता मनी विचार ॥ १४६ ॥
जो गुरू वर श्रद्धा ठेवणार । त्याचे कोठार भरणार । देवाण घेवाण होणार । सर्व सुरळीत होणार ॥ १४७ ॥
गणामहाराज सांगे भक्तांस । प्रसंग पटवावे मनास । भोगावे पूर्वसंचितास । नको स्पर्धा भक्तीत ॥ १४८ ॥
गुरू स्मरणात आयुष्य घालवा । सत्संगतीत जीव रमवा । दंभाचार नसावा । नवस न बाकी ठेवावा ॥ १४९ ॥

॥ शुभं भवतु ॥
॥ श्री गणपती सरस्वतीर्पणमस्तु ॥
॥ इति श्रीगुरूगजाननलीला ग्रंथस्य दशमोऽध्यायः संपूर्णः ॥
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
